// imports react and next
import React, { useEffect, useMemo, useState } from "react";
import dynamic from "next/dynamic";

// imports components
const OwlCarousel = dynamic(import("react-owl-carousel"), { ssr: false });
const AppLoader = dynamic(import("@/common/AppLoader"));

// imports assets
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const CustomComponentSlider = (props) => {
  const { customOptions, children, customClasses = "" } = props;
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowData(true);
    }, 500);
  }, []);

  const options = useMemo(() => {
    return {
      loop: true,
      nav: false,
      lazyLoad: true,
      margin: 0,
      dots: true,
      smartSpeed: 800,
      lazyLoadEager: 3,
      responsive: {
        320: {
          items: 1,
        },
        576: {
          items: 1,
        },
        992: {
          items: 1,
        },
        1200: {
          items: 1,
        },
      },
      ...customOptions,
    };
  }, [customOptions]);

  return (
    <>
      {showData ? (
        <OwlCarousel
          className={`owl-carousel owl-theme owl-hover owl-tamaro owl-custom owl-menu-nav min-h-[auto] ${customClasses}`}
          {...options}
        >
          {children}
        </OwlCarousel>
      ) : (
        <AppLoader />
      )}
    </>
  );
};

export default CustomComponentSlider;
